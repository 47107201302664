import React, { useEffect } from 'react'


import Layout from '../components/layout'
import { Contact } from '../components/contact'
import { SmallTitlePage } from '../components/common/smallTitlePage'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'



const BydgoszczKontakt = () => {

  const data = useStaticQuery(graphql`
  {
      file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            
          )
        }
      }
    }
`)

  return (
    <Layout bydgoszcz>
      <Seo
        title="Bydgoszcz kontakt"
        description="Kontakt w BodyMed Group Bydgoszcz."
        url="https://bodymedgroup.pl/bydgoszcz-kontakt"
      />
      <SmallTitlePage
        title='Kontakt' 

      />
      <Contact bydgoszcz/>
    </Layout>
  )
}

export default BydgoszczKontakt
